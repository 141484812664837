import { Link } from "react-router-dom";
function Menu() {
    return (
  
      <div id="menu" className="flex p-4 bg-blue-300">
        <style>
        {`@media print {#menu{display: none;}}`}
        </style>
        <Link className="flex bg-blue-400 flex-col flex-grow mx-1 md:mx-2 content-center items-center shadow-md" to="/"><div > <p className="text-l md:text-2xl font-bold">Zalecenia</p> </div></Link>
        <Link className="flex bg-blue-400 flex-col flex-grow mx-1 md:mx-2 content-center items-center shadow-md" to="/czesci"><div > <p className="text-l md:text-2xl font-bold">Części</p> </div></Link>
        <Link className="flex bg-blue-400 flex-col flex-grow mx-1 md:mx-2 content-center items-center shadow-md" to="/uslugi"><div > <p className="text-l md:text-2xl font-bold">Usługi</p> </div></Link>
      </div>
  
    );
  }
export default Menu;