
import axios from "axios";
import React, { useEffect, useState } from 'react';
import RenderIfVisible from 'react-render-if-visible'
import { Cookies } from 'react-cookie'

function Zlecenia() {
  console.log(process.env.REACT_APP_BACK_ADRESS);
  const [resp, setResp] = useState([]);
  const [respFilter, setRespFilter] = useState([]);
  const cookies = new Cookies();
  useEffect(() => {
  axios.get(process.env.REACT_APP_BACK_ADRESS+'/zlecenia',
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }}
    )
  .then(result =>  {  
    setResp(result.data.message);
    setRespFilter(result.data.message);
  })
  .catch(err => console.log(err));
}, []);

///// Wyszukiwamie
const [search, setSearch] = useState('')
const handleSearch = (e) => { 
  const searchTerm = e.target.value;
  setSearch(searchTerm)


  const filterResp = resp.filter((dat) => 
    dat.imie.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.nazwisko.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.firma.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.nip.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.miasto.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.ulica.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.produkt.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.mail.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.tel.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.seryjny.toLowerCase().includes(searchTerm.toLowerCase()) ||
    dat.usterka.toLowerCase().includes(searchTerm.toLowerCase())
    );
    searchTerm !== '' ?
    setRespFilter(filterResp) 
    :
    setRespFilter(resp)
  
}
let st="flex flex-col justify-evenly py-4 border-4 border-double border-black mb-6 mx-2 rounded-lg shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset]";



//// Zmiana wartości pól

const changeValue = (val,f,i) => {

  if (f === 'imie')
  resp[i].imie=val;
  if (f === 'nazwisko')
    resp[i].nazwisko=val;
  if (f === 'firma')
    resp[i].firma=val;
  if (f === 'nip')
    resp[i].nip=val;
  if (f === 'ulica')
    resp[i].ulica=val;
  if (f === 'nrb')
    resp[i].nrb=val;
  if (f === 'nrl')
    resp[i].nrl=val;
  if (f === 'miasto')
    resp[i].miasto=val;
  if (f === 'kod')
    resp[i].kod=val;
  if (f === 'mail')
    resp[i].mail=val;
  if (f === 'tel')
    resp[i].tel=val;
  if (f === 'produkt')
    resp[i].produkt=val;
  if (f === 'seryjny')
    resp[i].seryjny=val;
  if (f === 'usterka')
    resp[i].usterka=val;
  if (f === 'koniec')
    {
      if (val==='')
        val=null
    resp[i].koniec=val;
}
  if (f === 'wizyta')
    {
      if (val==='')
        val=null
    resp[i].wizyta=val;
}
  if (f === 'przyjete')
  {
    if (val==='')
      val=null
    resp[i].przyjete=val;
  }
  if (f === 'uwagi')
    resp[i].uwagi=val;
    let t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
    console.log(resp[i])
  }

///// Zapisz = Update

const zapisz = (i,id) => {

  if (resp[i].koniec !== null)
    resp[i].koniec=resp[i].koniec.slice(0, 19).replace('T', ' ')
  if (resp[i].wizyta !== null)
    resp[i].wizyta=resp[i].wizyta.slice(0, 19).replace('T', ' ')
  if (resp[i].przyjete !== null)
    resp[i].przyjete=resp[i].przyjete.slice(0, 19).replace('T', ' ')

  axios.patch(process.env.REACT_APP_BACK_ADRESS+'/zlecenia/'+id, {
    imie: resp[i].imie,
    nazwisko: resp[i].nazwisko,
    firma: resp[i].firma,
    nip: resp[i].nip,
    ulica: resp[i].ulica,
    nrb: resp[i].nrb,
    nrl: resp[i].nrl,
    miasto: resp[i].miasto,
    kod: resp[i].kod,
    mail: resp[i].mail,
    tel: resp[i].tel,
    produkt: resp[i].produkt,
    seryjny: resp[i].seryjny,
    usterka: resp[i].usterka,
    koniec: resp[i].koniec,
    wizyta: resp[i].wizyta,
    przyjete: resp[i].przyjete,
    uwagi: resp[i].uwagi
  },
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }}
  )
  .catch(err => console.log(err))

}

/// USUŃ

const usun = (i,id) => {
  // eslint-disable-next-line no-restricted-globals
  if (confirm("Czy jesteś pewny ???") === true) {
  axios.delete(process.env.REACT_APP_BACK_ADRESS+'/zlecenia/'+id,
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }}
    )
  .then(result =>  { 
    window.location.reload(); 
  })
  .catch(err => console.log(err))
  }
}



    return (

      <div className="flex flex-col bg-sky-100">
      <div className="flex m-2">    
        <input
          id="search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 "
          type="text"
          value={search}
          onChange={handleSearch}
          placeholder='Szukaj'
  />
      </div>
  {
       respFilter.map((data,i) => (
        <RenderIfVisible key={'v'+data.id}>

      <div key={data.id} className={ data.koniec !== null ? 'bg-rose-200 '+st : data.przyjete !== null ? 'bg-yellow-200 '+st : 'bg-blue-200 '+st }>

        {
        data.imie !== '' && data.nazwisko ?
          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-sky-600 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Imię</label>
            <input type="text" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={data.imie} onChange={(e) => changeValue(e.target.value,'imie',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nazwisko</label>
            <input type="text" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={data.nazwisko} onChange={(e) => changeValue(e.target.value,'nazwisko',i)} />
          </div>
        :
          null
        }
        {
        data.firma !== '' ?
          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-sky-600 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Firma</label>
            <input type="text" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={data.firma} onChange={(e) => changeValue(e.target.value,'firma',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nip</label>
            <input type="text" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={data.nip} onChange={(e) => changeValue(e.target.value,'nip',i)} />
          </div>
          :
          null

        }
          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Ulica</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.ulica} onChange={(e) => changeValue(e.target.value,'ulica',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nr. budynku</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.nrb} onChange={(e) => changeValue(e.target.value,'nrl',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nr. Mieszkania</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.nrb} onChange={(e) => changeValue(e.target.value,'nrb',i)} />
          </div>

          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Miasto</label>
            <input type="text" className="lg:w-2/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.miasto} onChange={(e) => changeValue(e.target.value,'miasto',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Kod</label>
            <input type="text" className="lg:w-2/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.kod} onChange={(e) => changeValue(e.target.value,'kod',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Mail</label>
            <input type="text" className="lg:w-2/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.mail} onChange={(e) => changeValue(e.target.value,'mail',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Telefon</label>
            <input type="text" className="lg:w-2/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.tel} onChange={(e) => changeValue(e.target.value,'tel',i)} />
          </div>

          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Model</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.produkt} onChange={(e) => changeValue(e.target.value,'produkt',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nr. seryjny</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.seryjny} onChange={(e) => changeValue(e.target.value,'seryjny',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">typ usługi</label>
            <input type="text" className="lg:w-3/12 md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.typ} disabled/>
          </div>

          <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 items-center mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Usterka</label>
            <textarea className="md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"defaultValue={data.usterka} onChange={(e) => changeValue(e.target.value,'usterka',i)} ></textarea>
          </div>

          <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 items-center mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Uwagi</label>
            <textarea className="md:text-base text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5" defaultValue={data.uwagi} onChange={(e) => changeValue(e.target.value,'uwagi',i)} ></textarea>
          </div>

          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Stworzono:</label>
            <input type="datetime-local" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={(data.stworzono || '').toString().substring(0, 16)} disabled/>
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Przyjęto</label>
            <input type="datetime-local" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={(data.przyjete || '').toString().substring(0, 16)} onChange={(e) => changeValue(e.target.value,'przyjete',i)} />
          </div>

          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Wizyta</label>
            <input type="datetime-local" className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={(data.wizyta || '').toString().substring(0, 16)} onChange={(e) => changeValue(e.target.value,'wizyta',i)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Zakończono</label>
            <input type="datetime-local"  className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={(data.koniec || '').toString().substring(0, 16)} onChange={(e) => changeValue(e.target.value,'koniec',i)} />
          </div>

          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
          <button className="mt-2 bg-green-300 mx-1 hover:bg-green-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-green-700 hover:border-green-500 rounded" onClick={() => zapisz(i,data.id)} >Zapisz</button>
          {
            data.przyjete === null && data.koniec === null ?
            <button className="mt-2 bg-amber-300 mx-1 hover:bg-amber-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-amber-700 hover:border-amber-500 rounded" onClick={() => window.open('/przyjmij/'+data.id, "_self")}  >Przyjmij</button>
            :
            null
          }          
          {
            data.przyjete  ?
            <button className="mt-2 bg-lime-300 mx-1 hover:bg-lime-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-lime-700 hover:border-lime-500 rounded" onClick={() => window.open('/napraw/'+data.id, "_self")}  > Napraw </button>
            :
            null
          }
          {
            data.koniec !== null ?
            <button className="mt-2 bg-indigo-300 mx-1 hover:bg-indigo-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-indigo-700 hover:border-indigo-500 rounded" onClick={() => window.open('/faktura/'+data.id, "_self")} >Faktura</button>
            :
            null
          }
            <button className="mt-2 bg-red-300 mx-1 hover:bg-red-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-red-700 hover:border-red-500 rounded" onClick={() => usun(i,data.id)} >Usuń</button>
         </div>

        </div>
        </RenderIfVisible>
  ))} 
    </div>

  
    );
  }
export default Zlecenia;