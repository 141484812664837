
import axios from "axios";
import {sha512} from 'crypto-hash';

function Login({setloggedIn, setCookie, password,login, setLogin, setPassword}) {

  const handleSubmit = (e) =>{
    e.preventDefault()
    axios.post(process.env.REACT_APP_BACK_ADRESS+'/login' ,{ 
      login: login,
      password: password
    })
    .then(result =>  {  
      if ( result.data.message === "true" )
      {
        setloggedIn(true);
        setCookie('jaJacek', password )
      }
      else
      {
        console.log("Błędne dane logowania lub inny błąd!!!");
      }
    })
    .catch(err => console.log(err))
  }



    return (

        <div className="flex items-center justify-center h-screen w-full px-5 sm:px-0">
        <div className="w-full p-8 lg:w-1/2">
          <div className="mt-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Login
            </label>
            <input
              className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
              type="text"
              onChange={(e)=> setLogin(e.target.value)}
              required
            />
          </div>
          <div className="mt-4 flex flex-col justify-between">
            <div className="flex justify-between">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Password
              </label>
            </div>
            <input
              className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
              type="password"
              onChange={(e)=> sha512(e.target.value).then(result =>  { setPassword(result) })}
              required
            />
          </div>
          <div className="mt-8">
            <button className="bg-blue-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-blue-600" onClick={handleSubmit}>
              Login
            </button>
          </div>
        </div>
      </div>

  
    );
  }
export default Login;