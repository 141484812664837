
import axios from "axios";
import { useParams } from 'react-router';
import { useState } from "react";
import { Cookies } from 'react-cookie'

function Przyjmij() {
    const params= useParams();
    const [date, setDate] = useState();
    const cookies = new Cookies();

    const przyjmij = () => {
        let id = params.id;
          axios.patch(process.env.REACT_APP_BACK_ADRESS+'/przyjmij/'+id, {
            wizyta: date
          },
          { 
            headers: {
            ps: cookies.get('jaJacek')
            }}
          )
          .then(result =>  {
            window.open('/', "_self")
    })
          .catch(err => console.log(err))
}


    return (
        <div className="mt-4 flex flex-col bg-sky-100">
          <div className="rounded-lg flex flex-row flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white  h-full">Wizyta</label>
            <input type="datetime-local"  className="md:w-5/12 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => setDate(e.target.value)} />
          
            <button className="mt-2 bg-amber-300 mx-1 hover:bg-amber-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-amber-700 hover:border-amber-500 rounded" onClick={(e) => przyjmij()}  >Przyjmij</button>
          
          </div>
          


        </div>
      
    );
  }
export default Przyjmij;