import React from 'react';

const Fakt = ({nrRach,date,nazwaS,nazwaJ,ulicaS,adresS,nipS,nazwaN,firmaN,ulicaN,adresN,nipN,zaplata, platnosc}) => {
let x,y,z,k;
 firmaN !=='' && firmaN !== undefined ?  x='11.4cm':x='10.7cm'
 firmaN !=='' && firmaN !== undefined ?  y='12.1cm':y='11.4cm'
 firmaN !=='' && firmaN !== undefined ?  z='12.8cm':z='12.1cm'
 firmaN !=='' && firmaN !== undefined ?  k='13.5cm':k='12.8cm'
return (

  <div>

    <div style={{position: 'relative',width:'210mm', height:'297mm'}} >
      <img style={{position: 'absolute',height:'1.4cm',width:'1.4cm',top:'2cm',left:'12cm'}}  src={process.env.PUBLIC_URL + '/logo.png'} alt="logo"/>
      <span style={{position: 'absolute',top:'2.3cm',left:'13.8cm', fontSize:'0.7cm'}} >Dyson Serwis Mobilny</span><br />
      <span style={{position: 'absolute',top:'2.6cm',left:'0.8cm', fontSize:'0.5cm'}} >Numer Rachunku: {nrRach}{new Date(date).toLocaleString('pl-PL').slice(2, 10).replace('.','/').replace('.','/')}</span><br />
      <span style={{position: 'absolute',top:'3.6cm',left:'0.8cm', fontSize:'0.5cm'}} >Data wystawienia: {date}</span><br />
      <span style={{position: 'absolute',top:'4.6cm',left:'0.8cm', fontSize:'0.5cm'}} >Data sprzedaży: {date}</span><br />
      <hr style={{position: 'absolute',top:'7.6cm', left:'5mm', width:'200mm', borderTop:'0.5mm solid'}} />

      <span style={{position: 'absolute',top:'10cm',left:'1.5cm', fontSize:'0.5cm', fontWeight:'bold'}} >SPRZEDAWCA:</span><br />
      <span style={{position: 'absolute',top:'10.7cm',left:'1.8cm', fontSize:'0.5cm'}} >{nazwaS}</span><br />
      <span style={{position: 'absolute',top:'11.4cm',left:'1.8cm', fontSize:'0.5cm'}} >{nazwaJ}</span><br />
      <span style={{position: 'absolute',top:'12.1cm',left:'1.8cm', fontSize:'0.5cm'}} >{ulicaS}</span><br />
      <span style={{position: 'absolute',top:'12.8cm',left:'1.8cm', fontSize:'0.5cm'}} >{adresS}</span><br />
      <span style={{position: 'absolute',top:'13.5cm',left:'1.8cm', fontSize:'0.5cm'}} >{nipS}</span><br />

      <span style={{position: 'absolute',top:'10cm',left:'12cm', fontSize:'0.5cm', fontWeight:'bold'}} >NABYWCA:</span><br />
      <span style={{position: 'absolute',top:'10.7cm',left:'12.3cm', fontSize:'0.5cm'}}> {firmaN}</span><br />
      <span style={{position: 'absolute',top:x,left:'12.3cm', fontSize:'0.5cm'}} >{nazwaN}</span><br />
      <span style={{position: 'absolute',top:y,left:'12.3cm', fontSize:'0.5cm'}} >{ulicaN}</span><br />
      <span style={{position: 'absolute',top:z,left:'12.3cm', fontSize:'0.5cm'}} >{adresN}</span><br />
      <span style={{position: 'absolute',top:k,left:'12.3cm', fontSize:'0.5cm'}} >{nipN}</span><br />


    <table style={{position: 'absolute',top:'16cm', fontSize:'0.5cm', width:'17.4', textAlign:'center', width: '90%', marginLeft: '5%'}}>
      <tr>
        <th style={{textAlign:'center', border: '2px solid', backgroundColor:'lightgrey'}}>LP</th>
        <th style={{textAlign:'center', border: '2px solid', backgroundColor:'lightgrey'}}>Nazwa usługi</th>
        <th style={{textAlign:'center', border: '2px solid', backgroundColor:'lightgrey'}}>Ilość</th>
        <th style={{textAlign:'center', border: '2px solid', backgroundColor:'lightgrey'}}>Cena PLN</th>
        <th style={{textAlign:'center', border: '2px solid', backgroundColor:'lightgrey'}}>Wartość PLN</th>
      </tr>
      <tr>
        <td style={{textAlign:'center', border: '2px solid'}}>1</td>
        <td style={{textAlign:'center', border: '2px solid'}}>Naprawa serwisowa</td>
        <td style={{textAlign:'center', border: '2px solid'}}>1</td>
        <td style={{textAlign:'center', border: '2px solid'}}>{zaplata}</td>
        <td style={{textAlign:'center', border: '2px solid'}}>{zaplata}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      <td style={{textAlign:'center', border: '2px solid'}}>Razem</td>
      <td style={{textAlign:'center', border: '2px solid'}}>{zaplata}</td>
      </tr>
      

    </table>


 
      <hr style={{position: 'absolute',top:'22.1cm', left:'5mm', width:'200mm', borderTop:'0.5mm solid'}} />

    <span style={{position: 'absolute',top:'24cm',left:'1.8cm', fontSize:'0.5cm'}} >Do zapłaty: {zaplata} PLN</span><br />
    <span style={{position: 'absolute',top:'25cm',left:'1.8cm', fontSize:'0.5cm'}} >Zapłacono: {zaplata} PLN</span><br />
    <span style={{position: 'absolute',top:'26cm',left:'1.8cm', fontSize:'0.5cm'}} >Forma płatności: {platnosc} </span><br />


    <hr style={{position: 'absolute',top:'27.1cm', left:'5mm', width:'200mm', borderTop:'0.5mm solid'}} />

    </div>
  
  </div>
);

};
export default Fakt;