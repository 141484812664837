import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie'

function Uslugi() {
  const [resp, setResp] = useState([]);
  const [respFilter, setRespFilter] = useState([]);
  const cookies = new Cookies();
  useEffect(() => {
  axios.get(process.env.REACT_APP_BACK_ADRESS+'/uslugi',{
    headers: {
    ps: cookies.get('jaJacek')
    }
  })
  .then(result =>  {  
    setResp(result.data.message);
    setRespFilter(result.data.message);
  })
  .catch(err => console.log(err));
}, []);

///// Wyszukiwamie
const [search, setSearch] = useState('')
const handleSearch = (e) => { 
  const searchTerm = e.target.value;
  setSearch(searchTerm)


  const filterResp = resp.filter((dat) => 
    dat.nazwa.toLowerCase().includes(searchTerm.toLowerCase())
    );
    searchTerm !== '' ?
    setRespFilter(filterResp) 
    :
    setRespFilter(resp)
  
}


///otwieranie 
const [showRest, setShowRest] = useState({});
const toggleShowRest = id => {
  setShowRest(prevOpen => ({
    ...prevOpen,
    [id]: !prevOpen[id]
  }));
};
  /// Cena 
  const Cena = (i) => {
    let t,v,tmp = resp;
    v=document.getElementById('cena').value;
    tmp[i].cena =parseInt(v);
    setResp(tmp);
    t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
  }


  /// Usuń pasuje
  const removePasuje = (i,li) => {
    let t,tmp = resp;
    tmp[i].pasuje = tmp[i].pasuje.split(';').filter(function (el) { return el !== li }).join(';');
    setResp(tmp);
    t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
    setResp(tmp);
    t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
  }

/// dodanie pasuje do
const addPasuje = (i) => {
  let t,v,k,tmp = resp;
  v=document.getElementById('addpasuje').value;
  if (v==='wszystkich')
  {
    tmp[i].pasuje=v;
    setResp(tmp);
    t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
  }
  if ( v !== '')
  {
    k = tmp[i].pasuje.split(';');
    k = k.filter(function (el) { return el !== '' });
    k.push(v);
    tmp[i].pasuje = k.join(';');
    setResp(tmp);
    t=search;
    setSearch(search+' ');
    setTimeout(() => {setSearch(t) }, 100);
  }
  
}



////// ZAPISZ /////

const zapisz = (i,id) => {
  axios.patch(process.env.REACT_APP_BACK_ADRESS+'/uslugi/'+id, {
    pasuje: resp[i].pasuje,
    cena: resp[i].cena
  },
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }
  }
)
  .catch(err => console.log(err))

}

// DODAJ
const dodaj = () => {
  let v=document.getElementById('nzw').value;
  if (v !== '')
  {
  axios.post(process.env.REACT_APP_BACK_ADRESS+'/uslugi/', {
    nazwa: v
  },
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }}
  )
  .then(result =>  {  
    window.location.reload();
  })
  .catch(err => console.log(err))
  }
}
 
///. USUŃ

const usun = (i,id) => {
  // eslint-disable-next-line no-restricted-globals
  if (confirm("Czy jesteś pewny ???") === true) {
  axios.delete(process.env.REACT_APP_BACK_ADRESS+'/uslugi/'+id,{ 
    headers: {
    ps: cookies.get('jaJacek')
    }})
  .then(result =>  { 
    window.location.reload(); 
  })
  .catch(err => console.log(err))
  }
}

    return (
        <div className="flex flex-col">
          <div className="flex m-2">  
            <div className="w-full flex flex-col justify-center items-center">
              <input id="nzw" className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Nazwa..." />
              <button className="mt-2 bg-green-300 mx-1 hover:bg-green-400 text-white font-bold py-1 md:py-2 px-2 md:px-4 border-b-4 border-green-700 hover:border-green-500 rounded" onClick={() => dodaj()}>Dodaj</button>
            </div>

          </div>
          <div className="flex m-2">    
            <input
              id="search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder='Szukaj'
      />
          </div>
      {
           respFilter.map((data,i) => (
          <div key={data.id} className="flex flex-col pt-2 mb-4 mx-2 rounded-lg bg-blue-200 pl=2 border-2 border-black">
            <div className="flex items-center justify-between">
              <div className="w-3/4 md:text-lg font-bold pl-2 md:pl-4">{data.nazwa}</div>
              <div className="w-1/16 md:text-lg font-bold mr-1">{data.stan}</div>
            </div>
            {showRest[data.id] ? 
            <div className="">
              <div className="w-full flex flex-col bg-blue-300 my-3 py-2">
                <h1 className="text-lg font-bold dark:text-white">Pasuje do:</h1>

                <div className="flex justify-center my-3">
                  <input id="addpasuje" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" />
                  <button className="ml-2 mt-1 bg-green-500 hover:bg-green-700 text-white font-bold rounded px-2" onClick={() => addPasuje(i)} >Dodaj</button>
                </div>
                <div className="flex flex-row flex-wrap justify-evenly">
                {
                data.pasuje.split(';').filter(function (el) { return el !== ''}).map((li,a) => (
                  <div key={'a'+a} className=" my-2 bg-blue-200 px-4"> 
                    <div className="">
                      <li className="flex font-bold justify-center"> {li}  </li>
                      <button className="mt-1 bg-red-500 hover:bg-red-700 text-white font-bold px-4" onClick={() => removePasuje(i, li)}>Usuń</button>
                    </div>
                  </div>  
                             
                
                ))
                }
                </div>
              </div>
              <div className="flex flex-row justify-evenly">
                <div className="md:w-1/5 mx-2 mb-6">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cena:</label>
                  <input id="cena" type='number' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={data.cena} onChange={() => Cena(i)} />
                </div>
              </div>
              <button className="w-full mb-4 mt-1 bg-red-500 hover:bg-red-700 text-white font-bold" onClick={() => usun(i,data.id)} > Usuń </button>
            </div>
            : null}            
            <button className="w-half mt-1 bg-rose-500 hover:bg-rose-700 text-white font-bold" onClick={() => zapisz(i,data.id)} > Zapisz </button>
            <button className="w-full mt-1 bg-blue-500 hover:bg-blue-700 text-white font-bold" onClick={() => toggleShowRest(data.id)} > &#8595; </button>
          </div>
          
      ))} 
        </div>

  
    );
  }
export default Uslugi;