import Menu from './elements/Menu';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Zlecenia from './elements/Zlecenia';
import Czesci from './elements/Czesci';
import Uslugi from './elements/Uslugi';
import Login from './elements/Login';
import Przyjmij from './elements/Przyjmij';
import Naprawa from './elements/Naprawa';
import Faktura from './elements/Faktura';
import Fakt from "./pdf/Fakt";
import { useState } from 'react';
import { useCookies } from 'react-cookie'

function App() {
  const [loggedIn, setloggedIn] = useState(false);
  const [cookie, setCookie] = useCookies();
  const [password, setPassword] = useState('');
  const [login ,setLogin] = useState('');

  return (
    <>
    {
       loggedIn ||  cookie.jaJacek ?   
    
    <Router>
      <Routes>
        <Route path="/" element={ <><Menu /><Zlecenia /></>} />
        <Route path="/czesci" element={ <><Menu /><Czesci  /></>} />
        <Route path="/uslugi" element={ <><Menu /><Uslugi /></>} />
        <Route path="/przyjmij/:id" element={ <><Menu /><Przyjmij /></>} />
        <Route path="/napraw/:id" element={ <><Menu /><Naprawa /></>} />
        <Route path="/faktura/:id" element={ <><Menu /><Faktura /></>} />
        <Route name="fakt" path="/fakt" element={ <Fakt />} />
      </Routes>
    </Router>
      :
        <Login setloggedIn={setloggedIn} setCookie={setCookie} password={password} login={login} setPassword={setPassword} setLogin={setLogin} />
      }
    </>
  );
}

export default App;
