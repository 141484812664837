
import axios from "axios";
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie'

function Napraw() {
const params= useParams();
const [resp, setResp] = useState([]);
const [czesci, setCzesci] = useState([]);
const [uslugi, setUslugi] = useState([]);
const [czesciUzyte, setCzesciUzyte] = useState([]);
const [uslugiUzyte, setUslugiUzyte] = useState([]);
const [model, setModel] = useState();
const cookies = new Cookies();
useEffect(() => {
axios.get(process.env.REACT_APP_BACK_ADRESS+'/naprawa/'+params.id,
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }}
  )
.then(result =>  {  

    if (!result.data.data[0] )
    {
        axios.get(process.env.REACT_APP_BACK_ADRESS+'/zlecenie/'+params.id,
          { 
            headers: {
            ps: cookies.get('jaJacek')
            }})
        .then(result =>  {  
            axios.post(process.env.REACT_APP_BACK_ADRESS+'/naprawa/', {
                idn: params.id,
                imie: result.data.data[0].imie,
                nazwisko: result.data.data[0].nazwisko,
                firma: result.data.data[0].firma,
                nip: result.data.data[0].nip,
                ulica: result.data.data[0].ulica,
                nrb: result.data.data[0].nrb,
                nrl: result.data.data[0].nrl,
                miasto: result.data.data[0].miasto,
                kod: result.data.data[0].kod,
                mail: result.data.data[0].mail

            },
            { 
              headers: {
              ps: cookies.get('jaJacek')
              }}
            )
            .then(result =>  {
                axios.get(process.env.REACT_APP_BACK_ADRESS+'/naprawa/'+params.id,
                  { 
                    headers: {
                    ps: cookies.get('jaJacek')
                    }}
                  )
                .then(res =>  {  
                    setResp(res.data.data[0]);
                })
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));

   
    }   
    else
  {  
  setResp(result.data.data[0]);
  setCzesciUzyte(JSON.parse(decodeURI(result.data.data[0].czesci).replace(/&quot;/g,'"')));
  setUslugiUzyte(JSON.parse(decodeURI(result.data.data[0].uslugi).replace(/&quot;/g,'"')));
  }
  axios.get(process.env.REACT_APP_BACK_ADRESS+'/zlecenie/'+params.id,
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }})
  .then(resu =>  {  
    setModel(resu.data.data[0].produkt);
  })
  .catch(err => console.log(err));

  axios.get(process.env.REACT_APP_BACK_ADRESS+'/czesci',
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }})
  .then(result =>  {  
    setCzesci(result.data.message);
  })
  .catch(err => console.log(err));

  axios.get(process.env.REACT_APP_BACK_ADRESS+'/uslugi',
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }})
  .then(result =>  {  
    setUslugi(result.data.message);
  })
  .catch(err => console.log(err));


})
.catch(err => console.log(err));
}, []);

useEffect(() => {

    let id = resp.id, status = resp.status,czesci,uslugi;
    if (czesciUzyte !== null)
      czesci = encodeURI(JSON.stringify(czesciUzyte))
    else
    czesci=null

    if (uslugiUzyte !== null)
      uslugi = encodeURI(JSON.stringify(uslugiUzyte))
    else
    uslugi=null



    axios.patch(process.env.REACT_APP_BACK_ADRESS+'/naprawa/'+id, {
        czesci: czesci,
        uslugi: uslugi,
        status: status,
    },
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }}
    ).catch(err => console.log(err))
},[czesciUzyte,uslugiUzyte,resp.status])


const uzyjCzesc = (nzw,cena,id) => {
    if (czesciUzyte !== null)
      setCzesciUzyte([
        ...czesciUzyte,
        { id:id,nazwa:nzw,cena:cena}
      ])
    else
    setCzesciUzyte([{ id:id,nazwa:nzw,cena:cena}])
    let stan,pasuje,cen,kupione;
    setCzesci(czesci.map(cz => {
        if (cz.id === id) {
            stan = cz.stan=cz.stan-1;
          return { ...cz, stan: stan };
        } else {
          // No changes
          return cz;
        }
      }));
      czesci.map(cz => {
        if (cz.id === id) {
            stan = cz.stan;
            pasuje = cz.pasuje;
            cena = cen;
            kupione = cz.kupione;

        }
      })
    
      axios.patch(process.env.REACT_APP_BACK_ADRESS+'/czesci/'+id, {
        pasuje: pasuje,
        cena: cen,
        kupione: kupione,
        stan: stan
      },
      { 
        headers: {
        ps: cookies.get('jaJacek')
        }}
      )
      .catch(err => console.log(err))


}
const usunCzesc = (nzw,cena,id) => {
    setCzesciUzyte(
        czesciUzyte.filter(a =>
          a.id !== id
        )
      );
      let stan,pasuje,cen,kupione;
      setCzesci(czesci.map(cz => {
        if (cz.id === id) {
            stan = cz.stan=cz.stan+1;
          return { ...cz, stan: stan };
        } else {
          // No changes
          return cz;
        }
      }));
      czesci.map(cz => {
        if (cz.id === id) {
            stan = cz.stan;
            pasuje = cz.pasuje;
            cena = cen;
            kupione = cz.kupione;

        }
      })
    
      axios.patch(process.env.REACT_APP_BACK_ADRESS+'/czesci/'+id, {
        pasuje: pasuje,
        cena: cen,
        kupione: kupione,
        stan: stan
      },
      { 
        headers: {
        ps: cookies.get('jaJacek')
        }}
      )
      .catch(err => console.log(err))

}
const uzyjUsluge = (nzw,cena,id) => {
    if (uslugiUzyte !== null)
    setUslugiUzyte([
        ...uslugiUzyte,
        { id:id,nazwa:nzw,cena:cena}
      ])
      else 
      setUslugiUzyte([{ id:id,nazwa:nzw,cena:cena}])
}
const usunUsluge = (nzw,cena,id) => {
    setUslugiUzyte(
        uslugiUzyte.filter(a =>
          a.id !== id
        )
      );
}


const zakoncz = () => {
    setResp({
        ...resp,
        status: "zakończono"
      });
    axios.patch(process.env.REACT_APP_BACK_ADRESS+'/zakoncz/'+resp.idn, {
        zakoncz: 'koniec'
      },
      { 
        headers: {
        ps: cookies.get('jaJacek')
        }}
    ).then(r =>  {
      window.open('/', "_self")
      })
      .catch(err => console.log(err))

}
    return (
        <div className="mt-4 flex flex-row bg-slate-100 w-full flex-wrap">
            <div id="czd" className="w-1/2 text-center flex flex-col border-2 border-black py-5 rounded-xl">
                <h1 className="py-2 text-xl font-bold">Dostępne Części</h1>
            {
            
                czesci.map((datac,i) => (
                    datac.pasuje.includes(model) || datac.pasuje.includes('wszystkich') ?
                    datac.stan > 0 ?
                        <button key={'c'+i} className="ml-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" onClick={() => uzyjCzesc(datac.nazwa,datac.cena,datac.id)}>{datac.nazwa+' -> '+datac.cena+'zł'}</button>
                        :null:null
                
                    
                ))
            }
            </div>
            <div id="czu" className="w-1/2 text-center flex flex-col border-2 border-black py-5 rounded-xl">
                <h1 className="py-2 text-xl font-bold">Użyte Części</h1>
                {
                    czesciUzyte !== null ?
                    czesciUzyte.map((ucz,i) => (
                        <button key={'uc'+i} className="ml-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" onClick={() => usunCzesc(ucz.nazwa,ucz.cena,ucz.id) }>{ucz.nazwa+' -> '+ucz.cena+'zł'}</button>

                    ))
                    :null
                }
            </div>
                <div id="usd" className="w-1/2 text-center flex flex-col border-2 border-black py-5 rounded-xl">
                <h1 className="py-2 text-xl font-bold">Dostępne Usługi</h1>
            {
                uslugi.map((datau,i) => (
                    datau.pasuje.includes(model) || datau.pasuje.includes('wszystkich') ?
                    <button key={'u'+i} className="ml-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" onClick={() => uzyjUsluge(datau.nazwa,datau.cena,datau.id)}>{datau.nazwa+' -> '+datau.cena+'zł'}</button>
                    :null
                ))
            }
            </div>
            <div id="usu" className="w-1/2 text-center flex flex-col border-2 border-black py-5 rounded-xl">
                <h1 className="py-2 text-xl font-bold">Użyte Usługi</h1>
                {
                uslugiUzyte !== null ?
                    uslugiUzyte.map((uus,i) => (
                        <button key={'uu'+i} className="ml-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800" onClick={() => usunUsluge(uus.nazwa,uus.cena,uus.id)}>{uus.nazwa+' -> '+uus.cena+'zł'}</button>

                    ))
                    :null
                }
            </div>

            <button className="my-10 mx-4 w-full text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500" onClick={() => zakoncz()}>Zakończ</button>
        </div>
      
    );
  }
export default Napraw;