
import axios from "axios";
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie'
import Fakt from "../pdf/Fakt";
import Rap from "../pdf/Rap";

function Faktura() {

const params= useParams();
const [rachunek,SetRachunek] = useState({});

const [resp, setResp] = useState([]); //naprawa
const [rach, setRach] = useState([]); //rachunek
const [nrRach, setNrRach] = useState([]); //nr Rachunku
let now = new Date();
let day = ("0" + now.getDate()).slice(-2);
let month = ("0" + (now.getMonth() + 1)).slice(-2);
let today = now.getFullYear()+"-"+(month)+"-"+(day) ;
const [date, setDate] = useState(today); //data rachunku

// SPRZEDAWCA
const [nazwaS, setNazwaS] = useState("DMS Serwis Mobilny"); 
const [nazwaJ, setNazwaJ] = useState("Jacek Milczarek");
const [ulicaS, setUlicaS] = useState("ul. Wolska 87 lok. 59");
const [adresS, setAdresS] = useState("01-229 Warszawa, Polska");
const [nipS, setNipS] = useState("NIP: 5270024088");
//NABYWCA
const [nazwaN, setNazwaN] = useState(); 
const [firmaN, setFirmaN] = useState(); 
const [ulicaN, setUlicaN] = useState();
const [adresN, setAdresN] = useState();
const [nipN, setNipN] = useState();
// Cena
const [zaplata, setZaplata] = useState();
// Wykonane czynności
const [czesciUzyte, setCzesciUzyte] = useState([]);
const [uslugiUzyte, setUslugiUzyte] = useState([]);

/// Forma Płatności
const [platnosc, setPlatnosc] = useState('Karta płatnicza');

const cookies = new Cookies();

useEffect(() => {
axios.get(process.env.REACT_APP_BACK_ADRESS+'/naprawa/'+params.id,
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }})
.then(result =>  {  
  setResp(result.data.data[0]);
})
.catch(err => console.log(err));

axios.get(process.env.REACT_APP_BACK_ADRESS+'/rachunek',
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }}
  )
.then(result =>  {  
  setRach(result.data.message);
})
.catch(err => console.log(err));
}, []);
useEffect(() => {
let nrTemp=0,dateTemp,rachDate;
if (rach[0] !== undefined)
{
dateTemp = date.slice(0, 19).replace('T', ' ')
rachDate = rach[0].data.slice(0, 19).replace('T', ' ')
dateTemp = new Date(dateTemp);
rachDate = new Date(rachDate);
if (rachDate.getMonth() === dateTemp.getMonth() && parseInt(params.id) !== parseInt(rach[0].idn))
  nrTemp=rach[0].dzis+1;
else
nrTemp=1;
setNrRach(nrTemp)
let chk = true;
rach.map( it => {
      if ( parseInt(it.idn) === parseInt(params.id))
      {
        chk=false;
        SetRachunek(it)
        setNrRach(it.dzis)
        return false
      }
})

if ( chk )
  {
    axios.post(process.env.REACT_APP_BACK_ADRESS+'/rachunek', {
      data: date,
      dzis: nrTemp,
      idn: params.id
    },
    { 
      headers: {
      ps: cookies.get('jaJacek')
      }}
    )
    .catch(err => console.log(err))
    }

}
}, [rach, date]);

useEffect(() => { 
  if (resp.firma !== '')
  {
    setFirmaN(resp.firma)
    setNipN(resp.nip)
  }
  if (resp.imie !== '' && resp.nazwisko)
  setNazwaN(resp.imie +' '+resp.nazwisko); 
  if ( resp.nrl !== '' )
  setUlicaN(resp.ulica+' '+resp.nrb+'/'+resp.nrl);
  else
  setUlicaN(resp.ulica+' '+resp.nrb);
  setAdresN(resp.kod+' '+resp.miasto);


  if (resp.czesci !== undefined && resp.uslugi !== undefined )
{
  let cz = JSON.parse(decodeURI(resp.czesci).replace(/&quot;/g,'"'));
  let us = JSON.parse(decodeURI(resp.uslugi).replace(/&quot;/g,'"'));
  setCzesciUzyte(cz);
  setUslugiUzyte(us);
}

}, [resp]);

useEffect(() => { 
  let cenaTmp=0;

 czesciUzyte.map( it => {
        cenaTmp = cenaTmp+parseInt(it.cena);
 })
 uslugiUzyte.map( it => {
  cenaTmp = cenaTmp+parseInt(it.cena);
})
setZaplata(cenaTmp)




}, [czesciUzyte, uslugiUzyte]);

useEffect(() => { 



}, [zaplata]);


const handleZapisz = () => {

  axios.patch(process.env.REACT_APP_BACK_ADRESS+'/rachunek/'+params.id, {
    data: date,
    dzis: nrRach
  },
  { 
    headers: {
    ps: cookies.get('jaJacek')
    }}
  )
  .catch(err => console.log(err))


  window.print()
}

    return (
      <>
        <div className="mt-4 flex flex-col bg-sky-100 faktura">
          <style>
        {`@media print {.faktura{display: none;}}`}
        </style>
        <style>
        {`@media print {#zap{display: none;}}`}
        </style>
        <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Data wystawienia faktury</label>
            <input type="date" defaultValue={rachunek.data} onChange={(e) => setDate(e.target.value)}/>
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Numer Faktury</label>
            <input type="number" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={rachunek.dzis} onChange={(e) => setNrRach(e.target.value)} />
        </div>
        <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nazwa Wystawiającego 1</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={nazwaS} onChange={(e) => setNazwaS(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nazwa Wystawiającego 2</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={nazwaJ} onChange={(e) => setNazwaJ(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Adres Wystawiającego 2</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={ulicaS} onChange={(e) => setUlicaS(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Miejscowość Wystawiającego</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={adresS} onChange={(e) => setAdresS(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nip Wystawiającego</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={nipS} onChange={(e) => setNipS(e.target.value)} />
        </div>
        <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Firma Nabywcy 1</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={firmaN} onChange={(e) => setFirmaN(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nazwa Nabywcy 2</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={nazwaN} onChange={(e) => setNazwaN(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Adres Nabywcy 2</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={ulicaN} onChange={(e) => setUlicaN(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Miejscowość Nabywcy</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={adresN} onChange={(e) => setAdresN(e.target.value)} />
            <label className="block text-sm font-medium text-gray-900 dark:text-white ">Nip Nabywcy 1</label>
            <input type="text" className="md:w-1/2 md:text-lg text-center font-bold p-1 md:p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" defaultValue={nipN} onChange={(e) => setNipN(e.target.value)} />
        </div>

        <div className="rounded-lg flex flex-col flex-wrap justify-evenly p-1 md:p-2 bg-blue-400 border-2 border-blue-500 mb-1 md:mx-2">
       <label className="block text-sm font-medium text-gray-900 dark:text-white ">Sposób zapłaty</label>
        <select onChange={(e) => setPlatnosc(e.target.value)} className="p-2 g-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-fullbg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full" >
          <option value="Karta płatnicza">Karta płatnicza</option>
          <option value="Blik">Blik</option>
          <option value="Przelew">Przelew</option>
        </select>     
        </div>

        </div>
        <button id="zap" onClick={(e) => handleZapisz()} type="button" className="w-full mt-5 px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">Zapisz</button>

        <Fakt nrRach={nrRach} 
        date={date} 
        nazwaS={nazwaS} nazwaJ={nazwaJ} ulicaS={ulicaS} 
        adresS={adresS} nipS={nipS} nazwaN={nazwaN} 
        firmaN={firmaN} ulicaN={ulicaN} adresN={adresN} 
        nipN={nipN} zaplata={zaplata} platnosc={platnosc}  />
        <Rap czesciUzyte={czesciUzyte} uslugiUzyte={uslugiUzyte} />
    </>
    )
  }
export default Faktura;