import React from 'react';


const Rap = ({czesciUzyte,uslugiUzyte}) => { 

    
return   (
<div style={{position: 'relative',width:'210mm', height:'297mm',marginTop:'5cm'}} >
<img style={{position: 'absolute',height:'1.4cm',width:'1.4cm',top:'2cm',left:'12cm'}}  src={process.env.PUBLIC_URL + '/logo.png'} alt="logo"/>
<span style={{position: 'absolute',top:'2.3cm',left:'13.8cm', fontSize:'0.7cm'}} >Dyson Serwis Mobilny</span><br />
<span style={{position: 'absolute',top:'6.6cm',left:'7.5cm', fontSize:'0.7cm', fontWeight:'bold'}} >Raport Serwisowy</span><br />
<div style={{position: 'absolute',top:'10cm',left:'2cm', textAlign:'center', width:'170mm'}}>
<div style={{fontSize:'0.5cm', fontWeight:'bold'}} >Użyte części:</div><br />

{

    czesciUzyte.length > 0 ?
    czesciUzyte.map( (it,i) => {
        return <p key={'c'+i} style={{fontSize:'0.5cm'}} >{it.nazwa}</p>

    })
    :
    <p style={{fontSize:'0.5cm'}} >Nie użyto części</p>

}


<div style={{marginTop: '2cm',fontSize:'0.5cm', fontWeight:'bold', marginTop:'2cm'}} >Wykonane usługi:</div><br />

{
 
uslugiUzyte.length > 0?
uslugiUzyte.map( (it,i) => {
    return <p key={'u'+i} style={{fontSize:'0.5cm'}} >{it.nazwa}</p>

})
:
<p style={{fontSize:'0.5cm'}} >Nie wykonano usług</p>

}

</div>
</div>
)
}
export default Rap;